
// Default settings
const defaults = {
  currentSite: {
    id: document.querySelector('html').getAttribute('data-site-id')
  },
  duration: {
    default: 800,
    fast: 300
  },
  easing: {
    default: 'easeOutQuint',
    css: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)'
  },
  elements: {
    $document: window.document,
    $window: window,
    $htmlBody: document.querySelector('html, body'),
    $html: document.querySelector('html'),
    $body: document.querySelector('body'),
  }
};

const meta = {
  elements: {
    seo_title: '.js-seo-title',
    seo_description: '.js-seo-description',
    seo_link: '.js-seo-link',
  },
  values: {
    seo_title: null,
    seo_description: null,
  },
  update: function() {
    const self = this;

    const meta = {
      seo_title: document.querySelector(self.elements.seo_title).textContent,
      seo_description: document.querySelector(self.elements.seo_description).textContent
    };
    
    const metaDescription = document.querySelector('meta[name="description"]'); // Get the <meta> element with the name "description"
    metaDescription.setAttribute("content", meta.seo_description); // Set the "content" attribute of the <meta> element to meta.seo_description

    const metaOgTitle = document.querySelector('meta[property="og:title"]'); // Get the <meta> element with the property "og:title"
    metaOgTitle.setAttribute("content", meta.seo_title); // Set the "content" attribute of the <meta> element to meta.seo_title
    document.querySelector('title').textContent = meta.seo_title; // Set the text content of the <title> element to meta.seo_title

    const metaOgDescription = document.querySelector('meta[property="og:description"]'); // Get the <meta> element with the property "og:description"
    metaOgDescription.setAttribute("content", meta.seo_description); // Set the "content" attribute of the <meta> element to meta.seo_description

    const metaOgUrl = document.querySelector('meta[property="og:url"]'); // Get the <meta> element with the property "og:url"
    metaOgUrl.setAttribute("content", window.location.href); // Set the "content" attribute of the <meta> element to the current URL

    self.values = meta;

    analytics.send.pageview(meta);
  }
};

const nav = {
  elements: {
    nav: '.h-nav',
    toggle: '.h-nav-toggle',
  },
  init: function() {
    const self = this;
    const navEl = document.querySelector(self.elements.nav);
    const toggleEl = document.querySelector(self.elements.toggle);

    toggleEl.addEventListener('click', function(){
      toggleEl.classList.toggle('is-active');
      navEl.classList.toggle('is-active');
      return false;
    });
  },

  close: function() {
    const self = this;
    const navEl = document.querySelector(self.elements.nav);
    const toggleEl = document.querySelector(self.elements.toggle);

    toggleEl.classList.remove('is-active');
    navEl.classList.remove('is-active');
  }
};

const lazy = {
  elements: {
    lazy: 'js-lazy',
    inline: 'js-lazy-inline',
  },
  init: function() {
    const self = this;
    console.log('init');

    const options = {
      elements_selector: '.' + self.elements.lazy,
      threshold: 1000,
      class_loaded: 'is-loaded',
      callback_loaded: function(el) {
        el.parentNode.classList.add('is-loaded');
      }
    };

    let lazyLoadInstance = new LazyLoad(options);
  }
};

const scrollTop = {
  elements: {
    trigger: '.h-scrolltop',
  },
  init: function() {
    const self = this;

    document.querySelector(self.elements.trigger).addEventListener('click', function() {
      self.go();
      return false;
    });
  },
  go: function() {
    const htmlBody = document.querySelector('html, body');

    htmlBody.animate({
      scrollTop: 0,
    }, 300);
  }
};

const tracking = {
  elements: {
    modal: '.h-tracking-modal',
    accept: '.h-tracking-accept',
  },
  init: function() {
    const self = this;

    if (Cookies.get('tracking') !== 'accepted') {
      document.querySelector(self.elements.modal).classList.add('is-active');
    } else {
      analytics.init();
    }

    document.querySelector(self.elements.accept).addEventListener('click', function(e) {
      e.preventDefault();
      analytics.init();
      Cookies.set('tracking', 'accepted', { expires: 7, path: '/' });
      document.querySelector(self.elements.modal).classList.remove('is-active');
    });
  }
};

const resizerAnimationStopper = {
  init: function() {
    let resizeTimer;
    window.addEventListener("resize", function() {
      defaults.elements.$body.classList.add("resize-animation-stopper");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        defaults.elements.$body.classList.remove("resize-animation-stopper");
      }, 400);
    });
  }
};

const analytics = {
  settings: {
    key: 'UA-169615851-1'
  },
  init: function(){
    var self = this;
    
    var ga = document.createElement('script')
      ga.type = 'text/javascript'
      ga.async = true
      ga.src = 'https://googletagmanager.com/gtag/js?id=' + self.settings.key;

    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);

    window.dataLayer = window.dataLayer || [];

    self.gtag('js', new Date());
    self.gtag('config', self.settings.key);
  },
  gtag: function() {
    if (!window.dataLayer) return;
    window.dataLayer.push(arguments);
  },
  send: {
    pageview: function(meta) {
      var self = analytics;

      self.gtag('config', self.settings.key, {
        'page_title' : meta.seo_title,
        'page_path': window.location.href
      });
    }
  }
};

const htmxEvents = {
  elements: {
    main: '.js-htmx-main',
    content: '.js-htmx-content',
    click: '.js-htmx-click',
    status: '.js-htmx-status',
    langLink: '.js-htmx-lang-link',
    onLoad: '.js-htmx-onload',
    $activeClick: null,
  },
  currentTarget: '.js-htmx-main',
  duration: 400,
  init: function() {
    const self = this;

    self.events();

    htmx.onLoad(function(){
    });

    Array.from(document.querySelectorAll(self.elements.onLoad)).forEach(function(element){
      const url = element.value;
      const target = element.getAttribute('hx-target');

      if (url) {
        htmx.ajax('GET', url, {target: target, swap:'innerHTML'}).then(function(){
          document.querySelector(target).classList.add('is-loaded');
        });
      }
    });
  },
  events: function() {
    const self = this;
    document.querySelector('body').addEventListener('click', function(event) {
      const target = event.target.closest(self.elements.click);
      if (!target) return;
      self.elements.$activeClick = target;
      self.currentTarget = target.getAttribute('hx-target');

      self.hideTarget();
    });

    document.querySelector('body').addEventListener('htmx:historyRestore', function() {
      self.showTarget();
    });

    document.querySelector('body').addEventListener('htmx:afterSwap', function() {
      self.showTarget();
    });
  },
  hideTarget: function() {
    const self = this,
      $target = document.querySelector(self.currentTarget);
    console.log($target);

    if ($target.classList.contains('js-htmx-main')) {
      Array.from(document.querySelectorAll(self.elements.click)).forEach(function(element) {
        element.classList.remove('is-active');
      });

      Array.from(document.querySelectorAll(self.elements.status)).forEach(function(element) {
        element.classList.remove('is-active');
      });

      self.elements.$activeClick.classList.add('is-active');

      const slug = self.elements.$activeClick.getAttribute('data-hx-slug');
      console.log('slug', slug);
      const linkElements = document.querySelectorAll(self.elements.click + '[data-hx-slug="' + slug + '"]');
      console.log(linkElements);
      linkElements.forEach(link => link.classList.add('is-active'));

      setTimeout(() => {
        nav.close();
        // theme.main(false);
      }, self.duration / 4);

      if (self.currentTarget == self.elements.main) {

        setTimeout(() => {
          document.documentElement.scrollTo({
            top: 0,
            // behavior: 'smooth'
          });
        }, self.duration / 2);
      }
    } else {
      Array.from(document.querySelectorAll(self.elements.click)).forEach(function(element) {
        if (element.getAttribute('hx-target') == self.currentTarget) {
          element.classList.remove('is-active');
        }
      });
      self.elements.$activeClick.classList.add('is-active');
    }

    $target.classList.remove('is-loaded');
  },
  showTarget: function() {
    const self = this,
      $target = document.querySelector(self.currentTarget);

    Array.from(document.querySelectorAll(self.elements.langLink)).forEach(function(element) {
      const language = element.getAttribute('data-language');
      const thisLanguageHref = document.querySelector(meta.elements.seo_link+'[data-language="'+language+'"]').value;

      console.log(language, thisLanguageHref);

      element.setAttribute('href', thisLanguageHref);
    });

    setTimeout(() => {
      $target.classList.add('is-loaded');
      pages.update();
      
      if (Cookies.get('tracking') == 'accepted') {
      }
    }, self.duration);
  },
  restart: function() {
    const self = this;

    document.querySelector('body').removeEventListener('click', function(event) {
      const target = event.target.closest(self.elements.click);
      if (!target) return;
      self.elements.$activeClick = target;
      self.currentTarget = target.getAttribute('hx-target');

      self.hideTarget();
    });

    document.querySelector('body').removeEventListener('htmx:historyRestore', function() {
      self.showTarget();
    });

    document.querySelector('body').removeEventListener('htmx:afterSwap', function() {
      self.showTarget();
    });

    self.events();
  }
};

const pages = {
  init: function() {
    resizerAnimationStopper.init();
    htmxEvents.init();
    lazy.init();
    scrollTop.init();
    nav.init();
    tracking.init();    
  },

  update: function() {
    lazy.init();
    meta.update();
  }
};

window.onload = function() {
  pages.init();
}